<template>
  <div class="accounting mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Abrechnungen</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-if="$permissions.hasPermission('users')">
          <v-card outlined>
            <v-card-title class="card-title-grey">
              Monatsabrechnung
            </v-card-title>
            <v-card-text>
              <v-select
                outlined
                dense
                :items="tenants"
                v-model="exports.tenant.tenantId"
                label="Mandant"
                item-text="text"
                item-value="value"
              ></v-select>
              <date-picker
                outlined
                dense
                label="Monat"
                v-model="exports.tenant.month"
                type="month"
                :format="'MM.YYYY'"
              ></date-picker>
              <p>
                An welche Adresse soll der Bericht geschickt werden?
              </p>
              <v-text-field
                outlined
                dense
                v-model="exports.tenant.email"
                label="E-Mail"
                type="text"
                :maxlength="255"
                :rules="emailRules"
              ></v-text-field>
              <v-btn
                large
                absolute
                bottom
                right
                color="primary"
                class="elevation-0"
                @click="exportMonthlyTenantReport()"
                :disabled="
                  exports.tenant.loading ||
                    !exports.tenant.tenantId ||
                    !exports.tenant.month ||
                    !exports.tenant.email
                "
              >
                Erstellen
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined>
            <v-card-title class="card-title-grey">
              Blocker
            </v-card-title>
            <v-card-text>
              <date-picker
                outlined
                label="Monat"
                v-model="exports.blocker.month"
                type="month"
                :format="'MM.YYYY'"
              ></date-picker>
              <p>
                An welche Adresse soll der Bericht geschickt werden?
              </p>
              <v-text-field
                outlined
                dense
                v-model="exports.blocker.email"
                label="E-Mail"
                type="text"
                :maxlength="255"
                :rules="emailRules"
              ></v-text-field>
              <v-btn
                large
                absolute
                bottom
                right
                color="primary"
                class="elevation-0"
                @click="exportMonthlyBlockerReport()"
                :disabled="
                  exports.blocker.loading ||
                    !exports.blocker.month ||
                    !exports.blocker.email
                "
              >
                Exportieren
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DatePicker from "@/components/forms/DatePicker.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    DatePicker
  },

  data: () => ({
    tenants: [],
    exports: {
      tenant: {
        loading: false,
        tenantId: null,
        month: null,
        email: null
      },
      blocker: {
        loading: false,
        month: null,
        email: null
      }
    },
    textRules: [v => !!v || "Muss ausgefüllt sein"],
    emailRules: [
      v =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "Ungültige E-Mail."
    ]
  }),

  computed: {
    ...mapState("user", ["user"])
  },

  created() {
    this.exports.tenant.email = this.user.email;
    this.exports.blocker.email = this.user.email;
    if (this.$permissions.hasPermission("users")) {
      this.fetchTenants();
    }
  },

  methods: {
    /**
     * Fetch all tenants.
     */
    fetchTenants() {
      this.$api.http
        .get(`backoffice/tenants?_per_page=99999`)
        .then(response => {
          this.tenants = response.data.data.map(tenant => {
            return {
              text: `${tenant.name}`,
              value: tenant.id
            };
          });
        });
    },

    /**
     * Export monthly tenant report.
     */
    exportMonthlyTenantReport() {
      this.exports.tenant.loading = true;
      this.$api.http
        .post(
          `backoffice/accounting/monthly-tenant-report/${this.exports.tenant.tenantId}`,
          {
            month: this.exports.tenant.month.format("YYYY-MM-DD"),
            email: this.exports.tenant.email
          }
        )
        .then(response => {
          this.exports.tenant.loading = false;
          this.exports.tenant.month = null;
          this.exports.tenant.tenantId = null;
          this.$toast.success(
            `Der Export wurde erstellt und an die Adresse ${this.exports.tenant.email} verschickt.`
          );
        })
        .catch(error => {
          this.exports.tenant.loading = false;
          this.$toast.error("Der Export konnte nicht erstellt werden.");
        });
    },

    /**
     * Export monthly tenant report.
     */
    exportMonthlyBlockerReport() {
      this.exports.blocker.loading = true;
      this.$api.http
        .post(`backoffice/accounting/monthly-blocker-report`, {
          month: this.exports.blocker.month.format("YYYY-MM-DD"),
          email: this.exports.blocker.email
        })
        .then(response => {
          this.exports.blocker.loading = false;
          this.exports.blocker.month = null;
          this.$toast.success(
            `Der Export wurde erstellt und an die Adresse ${this.exports.tenant.email} verschickt.`
          );
        })
        .catch(error => {
          this.exports.blocker.loading = false;
          this.$toast.error("Der Export konnte nicht erstellt werden.");
        });
    }
  }
};
</script>

<style lang="scss">
.accounting {
  .v-card {
    height: 100%;

    .v-card__text {
      padding-bottom: 60px;
    }

    .card-title-grey {
      background-color: #eee;
      padding: 10px 15px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
</style>
